import get from 'lodash/get';

import { type ErrorEvent, type EventHint } from '@sentry/react';

const rejectErrorMessages: Array<RegExp> = [
  /Failed to fetch dynamically imported module/,
  /Element type is invalid\. Received a promise that resolves to: undefined\./,
  /React ErrorBoundary Error: Element type is invalid\. Received a promise that resolves to: undefined\. Lazy element type must resolve to a class or function\./,
  /Error: Element type is invalid\. Received a promise that resolves to: undefined\./,
  /Lazy element type must resolve to a class or function\./,
  /Cannot read properties of undefined \(reading 'default'\)/,
  /Lazy/i,
  /dynamically imported/i,
];

export const handleRejectSentryErrors = (
  event: ErrorEvent,
  hint: EventHint
) => {
  const targetOriginalException = get(hint, 'originalException.message', '');
  const targetContextException = get(
    hint,
    'captureContext.contexts.react.componentStack',
    ''
  ) as string;

  if (
    rejectErrorMessages.some(
      (errorMessage) =>
        errorMessage.exec(targetOriginalException) ??
        errorMessage.exec(targetContextException)
    )
  ) {
    return null;
  }

  return event;
};
