import { type FieldValues, type UseFormSetError } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ROUTES } from '@constants';
import i18n from '@features/i18n';
import {
  type ICommonError,
  IErrorDetails,
  POST_MESSAGE_EVENT_TYPE,
} from '@modules/types';
import { sendPostMessage } from '@utils/sendPostMessage';

type SetErrorForm = UseFormSetError<FieldValues>;

const { ACTIVATE_CODE, GIVE_BONUS_MANAGEMENT } = ROUTES;

const showErrorToast = (errorData: IErrorDetails) => {
  const fallbackMessage = i18n.t(
    errorData?.title ?? 'common.toast.error.try.again'
  );

  const translationKey = errorData?.translationKey;
  const hasValidTranslation = translationKey && i18n.exists(translationKey);

  const errorMessage = hasValidTranslation
    ? i18n.t(translationKey)
    : `${fallbackMessage}${translationKey ? ` [${translationKey}]` : ''}`;

  toast.error(i18n.t('common.toast.error.process', { error: errorMessage }), {
    style: { whiteSpace: 'pre-wrap' },
  });
};

export const catchCommonError = (
  e: unknown,
  setErrorForm?: SetErrorForm
): void => {
  const isIframe = window.self !== window.top;

  if (
    isIframe &&
    window.location.pathname.includes(GIVE_BONUS_MANAGEMENT.ROOT)
  ) {
    sendPostMessage(POST_MESSAGE_EVENT_TYPE.ERROR_GIVE_BONUS_MODAL, e);
  }

  if (isIframe && window.location.pathname.includes(ACTIVATE_CODE.ROOT)) {
    sendPostMessage(POST_MESSAGE_EVENT_TYPE.ERROR_ACTIVATE_CODE_MODAL, e);
  }

  const { data } = (e as ICommonError) ?? {};

  data?.errors?.forEach((error) => {
    setErrorForm?.(
      error?.source?.fieldPointer,
      { message: error?.title },
      { shouldFocus: true }
    );

    showErrorToast(error);
  });

  if (!data?.errors?.length && data?.traceId) {
    toast.error(
      `${data?.status ?? ''}: ${data?.title ?? ''}\n${
        data?.detail ??
        i18n.t('common.toast.error.process', {
          error: i18n.t('common.toast.error.try.again'),
        })
      }`,
      { style: { whiteSpace: 'pre-wrap' } }
    );
  }
};
