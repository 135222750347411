import { menuConfig } from '@menu';

import { IMenuItem } from '@features/menu/menuSlice';

const dynamicRouteTitle: Record<string, string> = {
  'player-codes': 'Details',
  'player-offers': 'Details',
  'give-bonus': 'Give Bonus',
  'monitor-report': 'Details',
  'player-bonuses': 'Details',
  'bonus-management': 'Bonus',
  'activity-report': 'Details',
  'code-campaigns': 'Campaign',
  'activate-code': 'Activate Code',
  // Description: Refactor after Orbit impl in BS-4097
  'campaign-management': 'Campaign',
  'automatic-campaigns': 'Campaign',
  'scheduled-campaigns': 'Campaign',
};

const getMenuItemById = (
  menuConfig: IMenuItem[],
  id: string
): IMenuItem | undefined => {
  for (const item of menuConfig) {
    if (item.type === 'item' && item.id === id) {
      return item;
    }

    if (item.children) {
      const matchedSubItem = getMenuItemById(item.children, id);

      if (matchedSubItem) {
        return matchedSubItem;
      }
    }
  }

  return undefined;
};

export const getBreadcrumbsTitle = (
  str: string,
  pathnames: string[]
): string => {
  const menuItem = getMenuItemById(menuConfig, str);

  return menuItem?.title ?? dynamicRouteTitle[pathnames[0]];
};
