import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import i18n from '@features/i18n';
import { type Option } from '@shared/form/types';

type ResponseKey = 'code' | 'name' | 'title' | 'label' | 'displayName';

interface IResponseToSelectOptions {
  withId?: boolean;
  key: ResponseKey;
  withTranslation?: boolean;
}
export function responseToSelectOptions<T>(
  response: T,
  options?: IResponseToSelectOptions
): Option[] {
  if (isEmpty(response)) {
    return [];
  }

  if (Array.isArray(response)) {
    return response.map((item) => {
      let label: string = item;

      if (options?.withTranslation && item.translationKey) {
        label = i18n.t(item.translationKey);
      } else if (options?.key) {
        label = `${item[options.key]}`;
      }

      if (options?.key && options.withId) {
        label = `${label} (${item.id})`.trim();
      }

      return {
        label,
        value: item.id ?? item,
        ...(item.activationAction && {
          activationAction: item.activationAction,
        }),
      };
    });
  }

  if (isObject(response)) {
    return Object.entries(response).map(([key]) => ({
      label: key,
      value: key,
    }));
  }

  return [];
}
