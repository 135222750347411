export const ENV = {
  ...{
    // Description: build-time variables:
    MODE: import.meta.env.MODE,
    VITE_VERSION: import.meta.env.VITE_VERSION ?? '',
    VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN ?? '',
    VITE_BRANCH_NAME: import.meta.env.VITE_BRANCH_NAME ?? '',
    VITE_SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN ?? '',
  },

  // Description: runtime variables
  VITE_DOMAIN: window.globalENV?.VITE_DOMAIN ?? import.meta.env.VITE_DOMAIN,
  VITE_ENVIRONMENT:
    window.globalENV?.VITE_ENVIRONMENT ?? import.meta.env.VITE_ENVIRONMENT,
  VITE_BONUS_API_URL:
    window.globalENV?.VITE_BONUS_API_URL ?? import.meta.env.VITE_BONUS_API_URL,
  VITE_FEATURE_ENABLED:
    window.globalENV?.VITE_FEATURE_ENABLED ??
    import.meta.env.VITE_FEATURE_ENABLED,
  VITE_DEFAULT_JWT_TOKEN:
    window.globalENV?.VITE_DEFAULT_JWT_TOKEN ??
    import.meta.env.VITE_DEFAULT_JWT_TOKEN,
  VITE_BONUS_SERVICE_API_URL:
    window.globalENV?.VITE_BONUS_SERVICE_API_URL ??
    import.meta.env.VITE_BONUS_SERVICE_API_URL,
  VITE_BONUS_SERVICE_X_API_KEY:
    window.globalENV?.VITE_BONUS_SERVICE_X_API_KEY ??
    import.meta.env.VITE_BONUS_SERVICE_X_API_KEY,
  VITE_MAINTENANCE_MODE_MESSAGE:
    window.globalENV?.VITE_MAINTENANCE_MODE_MESSAGE ??
    import.meta.env.VITE_MAINTENANCE_MODE_MESSAGE,
  VITE_MAINTENANCE_MODE_ENABLED:
    window.globalENV?.VITE_MAINTENANCE_MODE_ENABLED ??
    import.meta.env.VITE_MAINTENANCE_MODE_ENABLED,
  VITE_PLAYER_REPORTING_SERVICE_API_URL:
    window.globalENV?.VITE_PLAYER_REPORTING_SERVICE_API_URL ??
    import.meta.env.VITE_PLAYER_REPORTING_SERVICE_API_URL,
};
