import { LocalizationProviderProps } from '@mui/x-date-pickers';
import { enUS, ptBR } from '@mui/x-date-pickers/locales';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Dayjs } from 'dayjs';

import translationEn from '@features/i18n/en-US/translation.json';
import translationMock from '@features/i18n/mock-for-qa/translation.json';
import translationPtBr from '@features/i18n/pt-BR/translation.json';

import 'dayjs/locale/pt-br';
import 'dayjs/locale/en';

enum LOCALE {
  EN_US = 'en-US',
  //Description: remove after QA verification in v4.10
  MOCK_FOR_QA = 'mock-for-qa',
  PT_BR = 'pt-BR',
}

const resources: Record<LOCALE, { translation: Record<string, string> }> = {
  [LOCALE.EN_US]: {
    translation: translationEn,
  },
  [LOCALE.PT_BR]: {
    translation: translationPtBr,
  },
  [LOCALE.MOCK_FOR_QA]: {
    translation: translationMock,
  },
};

const getLocaleFromQuery = (): LOCALE => {
  const params = new URLSearchParams(window.location.search);
  const language = params.get('language') as LOCALE;

  return Object.values(LOCALE).includes(language) ? language : LOCALE.EN_US;
};

export const getAdapterLocale = () => {
  const locales: Record<
    LOCALE,
    NonNullable<LocalizationProviderProps<Dayjs, string>['adapterLocale']>
  > = {
    [LOCALE.EN_US]: 'en',
    [LOCALE.PT_BR]: 'pt-br',
    [LOCALE.MOCK_FOR_QA]: 'en',
  };

  return locales[getLocaleFromQuery()] || locales[LOCALE.EN_US];
};

export const getLocaleText = () => {
  const localeTexts: Record<
    LOCALE,
    NonNullable<LocalizationProviderProps<Dayjs, string>['localeText']>
  > = {
    [LOCALE.PT_BR]:
      ptBR.components.MuiLocalizationProvider.defaultProps.localeText,
    [LOCALE.EN_US]:
      enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    [LOCALE.MOCK_FOR_QA]:
      enUS.components.MuiLocalizationProvider.defaultProps.localeText,
  };

  return localeTexts[getLocaleFromQuery()] || localeTexts[LOCALE.EN_US];
};

i18n.use(initReactI18next).init({
  resources,
  lng: getLocaleFromQuery(),

  interpolation: {
    escapeValue: false,
    format: function (value, format, _) {
      if (format === 'uppercase') {
        return value.toUpperCase();
      }
      if (format === 'lowercase') {
        return value.toLowerCase();
      }

      return value;
    },
  },
});

export const adapterLocale = getAdapterLocale();
export const localeText = getLocaleText();

export default i18n;
